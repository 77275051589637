/*
 * If not stated otherwise in this file or this component's LICENSE file the
 * following copyright and licenses apply:
 *
 * Copyright 2020 Metrological
 *
 * Licensed under the Apache License, Version 2.0 (the License);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import WebGLRenderer from "../webgl/WebGLRenderer.mjs";
import SparkShader from "./shaders/SparkShader.mjs";

export default class SparkRenderer extends WebGLRenderer {

    constructor(stage) {
        super(stage);
    }

    _createDefaultShader(ctx) {
        return new SparkShader(ctx);
    }

    createCoreRenderExecutor(ctx) {
        global.beginDrawing();
        let ret = super.createCoreRenderExecutor(ctx);
        global.endDrawing();
        return ret;
    }
}
