/*
 * If not stated otherwise in this file or this component's LICENSE file the
 * following copyright and licenses apply:
 *
 * Copyright 2020 Metrological
 *
 * Licensed under the Apache License, Version 2.0 (the License);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Texture from "../tree/Texture.mjs";

export default class SourceTexture extends Texture {

    constructor(stage) {
        super(stage);

        this._textureSource = undefined;
    }

    get textureSource() {
        return this._textureSource;
    }

    set textureSource(v) {
        if (v !== this._textureSource) {
            if (v.isResultTexture) {
                // In case of a result texture, automatically inherit the precision.
                this._precision = this.stage.getRenderPrecision();
            }
            this._textureSource = v;
            this._changed();
        }
    }

    _getTextureSource() {
        return this._textureSource;
    }

}
